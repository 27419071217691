import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path:'/tab',
    name:'tab',
    component:()=>import('../views/TabSetView.vue'),
    children:[
      {
        path:''
      }
    ]
  },
  {
    path:'/photo',
    name:'photo',
    component:()=>import('../views/PhotographerView.vue')
  },
  {
    path:'/recruit',
    name:'recruit',
    component:()=>import('../views/RecruitView.vue')
  },
  {
    path:'/activity',
    name:'activity',
    component:()=>import('../views/ActivityView.vue')
  },
  {
    path:'/record',
    name:'record',
    component:()=>import('../views/RecordView.vue')
  },
  {
    path:'/register',
    name:'register',
    component:()=>import('../views/RegisterView.vue')
  },
  {
    path:'/details',
    name:'details',
    component:()=>import('../views/DetailsView.vue')
  },
  {
    path:'/user',
    name:'user',
    component:()=>import('../views/UserView.vue')
  },
  {
    path:'/my',
    name:'my',
    component:()=>import('../views/MyView.vue')
  },
  {
    path:'/seach',
    name:'seach',
    component:()=>import('../views/SeachView.vue')
  }
]

// 注意:解决路由跳转问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err=>err);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 修改class
  // 带有自动激活的 CSS class 的链接
  linkActiveClass:"con",
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

export default router
