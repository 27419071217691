import { render, staticRenderFns } from "./App.vue?vue&type=template&id=38631b3b&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=38631b3b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38631b3b",
  null
  
)

export default component.exports