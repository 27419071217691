<template>
    <div class="foot">
            <div class="container">
                <div class="footTop">
                    <p>Copyright © 2023 fivefield.cn 版权所有</p>
                    <ul>
                        <li>使用条款</li>
                        <li>隐私政策</li>
                        <li>内容指引</li>
                        <li>网站反馈</li>
                        <li>常见问题</li>
                    </ul>
                    <p>中文 | English</p>
                </div>
                <div class="footBom">豫ICP备19030528号<div class="icon"><img src="../assets/img/footicon.gif" alt="" class="auto-img"></div></div>
            </div>
            
        </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
@import '../assets/css/foot.css'
</style>