import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//elm组件全部引入
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';//elm css样式
//按需加载
// import { Button } from 'element-ui';
// Vue.use(Button)
Vue.config.productionTip = false
//$cnpm i swiper@5 -S
import "swiper/css/swiper.min.css";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
