<template>
  <div>
    <!-- 导航栏 -->
    <head-navbar :token="token" @login_local="login_local"></head-navbar>
    
    <router-view @login_local="login_local"></router-view>
  </div>
</template>

<script>
import HeadNavbar from './components/HeadNavbar.vue';
  export default {
  components: { HeadNavbar },
    data() {
      return {
        // 登录状态
        token: false,
      };
    },
    methods:{
      // 判断登录状态方法
      login_local(){
        if(window.localStorage.getItem('token')){
          this.token=true;
        }else{
          this.token=false;
        }
      }
    },
    created(){
      this.login_local();

    }
  }
</script>

<style lang="scss" scoped>
@import './assets/css/default.css';
@import './assets/css/header.css';
@import './assets/css/foot.css';
</style>