<template>
    <div class="header">
            <div class="nav">
                <router-link to="/home" tag="div" class="logo">
                    <div class="nav-logo">五域摄影</div>
                </router-link>
                <!-- <div class="container"> -->
                    <div class="navtop">
                        <router-link to="/home">首页</router-link>
                        <router-link to="/about" >探索</router-link>
                        <router-link to="/tab">标签</router-link>
                        <router-link to="/photo">摄影师</router-link>
                        <router-link to="/recruit">签约摄影师招募</router-link>
                        <router-link to="/activity">热门活动</router-link>
                    </div>
                <!-- </div> -->
                <div class="seachbox">
                    <router-link tag="div" to="/seach" class="seach">
                        <!-- <input type="text"> -->
                        <div class="icon">
                            <img class="auto-img" src="../assets/img/seach.svg" alt="">
                        </div>
                    </router-link>
                    <div style="color:#fff;font-size:14px;cursor: pointer;padding:0 10px;" @click="$router.go(-1)">返回</div>

                    <router-link v-if="!token" to="/record" style="font-size:14px;">登录</router-link>
                    <router-link v-if="!token"  to="/register" style="font-size:14px;">注册</router-link>
                    
                    <div @click="clear" v-if="token" style="color:#fff;font-size:14px;cursor: pointer;padding:0 10px;">退出登录</div>
                    <router-link class="icon" v-if="token" tag="div" to="/my">
                        <img src="../assets/img/mine.svg" alt="" class="auto-img">
                    </router-link>
                </div>  
            </div>
        </div>
</template>

<script>
    export default {
     props:['token'],
        data() {
            return {
            };
        },
        methods:{
            // 退出登录
            clear(){
                window.localStorage.removeItem("token");
                window.localStorage.removeItem('userId');
                this.$emit("login_local");
                this.$message.success('退出成功');
                this.$router.push("/home");
            },
             openFullScreen2() {
                this.$router.push("/about");
                const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
                setTimeout(() => {
                loading.close();
                }, 2000);
            }
            },
    
    }
</script>

<style lang="scss" scoped>
@import '../assets/css/header.css';
.header{
    .seachbox{
        &>.icon{
            width: 18px;
            height: 18px;
            cursor: pointer;
            padding-left: 10px;
        }
    }
    .nav .logo{
        height: 24px;
    }
    .nav-logo{
        color: #ffffff;
        font-size: 24px;
    }
    
}
</style>