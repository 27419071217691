<template>
 <div class="home">
        <!-- 导航 -->
       

        <!-- 轮播图 -->
        <div class="container">
            <div class="banner" v-if="Banner">
               
                <div class="swiper mySwiper li">
                    <div class="swiper-wrapper">
                        <div v-for="(item) in Banner" :key="item.id" class="swiper-slide li">
                            <router-link tag="div" to="/details">
                                <img class="auto-img" :src="item.img" alt="">
                            </router-link>
                        </div>
                    </div>
      
                <div class="swiper-pagination com"></div>
        </div>
            </div>
        </div>

        <!-- 热点 -->
        <div class="container">
            <div class="hot">
                <div class="title">
                    <p>热门航拍点</p>
                    <router-link to="/about">热门航拍点集锦 ></router-link>
                </div>
                <div class="main">
                    
                        <div class="box">
                        <div class="imgbox">
                            <img class="auto-img" src="../assets/img/ia_100000161.jpg" alt="">
                        </div>
                        <p>澳大利亚</p>
                        </div>
                    
                    
                    
                    <div class="box">
                        <div class="imgbox">
                            <img class="auto-img" src="../assets/img/ia_100000162.jpg" alt="">
                        </div>
                        <p>美国</p>
                    </div>
                    
                    <div class="box">
                        <div class="imgbox">
                            <img class="auto-img" src="../assets/img/ia_100000163.jpg" alt="">
                        </div>
                        <p>冰岛</p>
                    </div>
                    <div class="box">
                        <div class="imgbox">
                            <img class="auto-img" src="../assets/img/ia_100000164.jpg" alt="">
                        </div>
                        <p>西藏</p>
                    </div>
                    <div class="box">
                        <div class="imgbox">
                            <img class="auto-img" src="../assets/img/ia_100000165.jpg" alt="">
                        </div>
                        <p>上海</p>
                    </div>
                    <div class="box">
                        <div class="imgbox">
                            <img class="auto-img" src="../assets/img/ia_100000166.jpg" alt="">
                        </div>
                        <p>新疆</p>
                    </div>
                </div>
            </div>
            
            
            
            
        </div><ul class="titlebox">
                <li>精选</li>
            </ul>
        <div class="line"></div>
        

        <div class="content">
            <div class="container">
                <div class="mainbox">
                    <div class="natural">
                        <div class="title">
                            <p>自然</p>
                            <div class="right">
                                <ol style="margin: 0;">
                                    <li v-for="(item,index) in 4" 
                                    :key="index" 
                                    @click="cut(index)" 
                                    :class="{'con':index==i}"
                                    >
                                    </li>
                                    
                                </ol>
                                <router-link to="/about">更多 ></router-link>
                            </div>                           
                        </div>
                        <div class="banner">
                           
                            <div class="bannerbox">
                                <ul ref="banner">
                                    <li v-for="(item) in Lists"  :key="item.slug">
                                        <!-- 路由跳转和浏览量加一 -->
                                        <div 
                                        @click="router_details(item.slug)" 
                                        class="imgbox back" 
                                        :style="{ 'background-image':'url('+item.image.large+')' }">
                                            <!-- <img class="auto-img" :src="item.image.large" alt=""> -->
                                        </div>
                                        <div class="content">
                                            <p @click="router_details(item.slug)" >{{item.title}}</p>
                                            <div class="device">
                                                设备：<span>{{item.equipment.name}}</span>
                                            </div>
                                            <div class="state">
                                                <div class="icon">
                                                    <img src="../assets/img/eyes.svg" alt="" class="auto-img">
                                                </div>
                                                <p>{{item.view_count}}</p>
                                                <!-- 点赞方法传入id -->
                                                <div @click="goods(item.slug)" class="icon" style="margin-left:15px;" >
                                                    <img src="../assets/img/goods.png" alt="" class="auto-img" v-show="item.goods==0"> 
                                                    <img src="../assets/img/good.png" alt="" class="auto-img" v-show="item.goods==1"> 
                                                </div>
                                                <p>{{item.like_count}}</p>
                                            </div>
                                            <div class="line"></div>
                                            <div class="user">
                                                <div class="userimg">
                                                    <div class="imgbox">
                                                        <img :src="item.user.avatar.large" alt="" class="auto-img">
                                                    </div>
                                                    <p @click="user(item.user.slug)">{{item.user.name}}</p>
                                                </div>
                                                <div class="time">{{item.created_at | Time}}</div>
                                            </div>
                                        </div>
                                    </li>
                                   
                                   
                                </ul>
                            </div>
                         
                        </div>
                    </div>
                    <div class="natural">
                        <div class="title">
                            <p>自然</p>
                            <div class="right">
                                <ol style="margin: 0;">
                                    <li v-for="(item,index) in 4" 
                                    :key="index" 
                                    @click="cut1(index)" 
                                    :class="{'con':index==j}"
                                    >
                                    </li>
                                    
                                </ol>
                                <router-link to="/about">更多 ></router-link>
                            </div>                           
                        </div>
                        <div class="banner">
                           
                            <div class="bannerbox">
                                <ul ref="banner1">
                                    <li v-for="(item) in Lists"  :key="item.slug">
                                        <!-- 路由跳转和浏览量加一 -->
                                        <div 
                                        @click="router_details(item.slug)" 
                                        class="imgbox back" 
                                        :style="{ 'background-image':'url('+item.image.large+')' }">
                                            <!-- <img class="auto-img" :src="item.image.large" alt=""> -->
                                        </div>
                                        <div class="content">
                                            <p @click="router_details(item.slug)" >{{item.title}}</p>
                                            <div class="device">
                                                设备：<span>{{item.equipment.name}}</span>
                                            </div>
                                            <div class="state">
                                                <div class="icon">
                                                    <img src="../assets/img/eyes.svg" alt="" class="auto-img">
                                                </div>
                                                <p>{{item.view_count}}</p>
                                                <!-- 点赞方法传入id -->
                                                <div @click="goods(item.slug)" class="icon" style="margin-left:15px;" >
                                                    <img src="../assets/img/goods.png" alt="" class="auto-img" v-show="item.goods==0"> 
                                                    <img src="../assets/img/good.png" alt="" class="auto-img" v-show="item.goods==1"> 
                                                </div>
                                                <p>{{item.like_count}}</p>
                                            </div>
                                            <div class="line"></div>
                                            <div class="user">
                                                <div class="userimg">
                                                    <div class="imgbox">
                                                        <img :src="item.user.avatar.large" alt="" class="auto-img">
                                                    </div>
                                                    <p @click="user(item.user.slug)">{{item.user.name}}</p>
                                                </div>
                                                <div class="time">{{item.created_at | Time}}</div>
                                            </div>
                                        </div>
                                    </li>
                                   
                                   
                                </ul>
                            </div>
                         
                        </div>
                    </div>
                    <div class="natural">
                        <div class="title">
                            <p>自然</p>
                            <div class="right">
                                <ol style="margin: 0;">
                                    <li v-for="(item,index) in 4" 
                                    :key="index" 
                                    @click="cut2(index)" 
                                    :class="{'con':index==k}"
                                    >
                                    </li>
                                    
                                </ol>
                                <router-link to="/about">更多 ></router-link>
                            </div>                           
                        </div>
                        <div class="banner">
                           
                            <div class="bannerbox">
                                <ul ref="banner2">
                                    <li v-for="(item) in Lists"  :key="item.slug">
                                        <!-- 路由跳转和浏览量加一 -->
                                        <div 
                                        @click="router_details(item.slug)" 
                                        class="imgbox back" 
                                        :style="{ 'background-image':'url('+item.image.large+')' }">
                                            <!-- <img class="auto-img" :src="item.image.large" alt=""> -->
                                        </div>
                                        <div class="content">
                                            <p @click="router_details(item.slug)" >{{item.title}}</p>
                                            <div class="device">
                                                设备：<span>{{item.equipment.name}}</span>
                                            </div>
                                            <div class="state">
                                                <div class="icon">
                                                    <img src="../assets/img/eyes.svg" alt="" class="auto-img">
                                                </div>
                                                <p>{{item.view_count}}</p>
                                                <!-- 点赞方法传入id -->
                                                <div @click="goods(item.slug)" class="icon" style="margin-left:15px;" >
                                                    <img src="../assets/img/goods.png" alt="" class="auto-img" v-show="item.goods==0"> 
                                                    <img src="../assets/img/good.png" alt="" class="auto-img" v-show="item.goods==1"> 
                                                </div>
                                                <p>{{item.like_count}}</p>
                                            </div>
                                            <div class="line"></div>
                                            <div class="user">
                                                <div class="userimg">
                                                    <div class="imgbox">
                                                        <img :src="item.user.avatar.large" alt="" class="auto-img">
                                                    </div>
                                                    <p @click="user(item.user.slug)">{{item.user.name}}</p>
                                                </div>
                                                <div class="time">{{item.created_at | Time}}</div>
                                            </div>
                                        </div>
                                    </li>
                                   
                                   
                                </ul>
                            </div>
                         
                        </div>
                    </div>
                   
                   
                   
                    
                    <div class="mainfoot">
                        <div class="title">
                            <p>热门标签</p>
                            <div class="right">
                                <ol style="margin: 0;">
                                    <li class="con"></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ol>
                                <a href="">更多 ></a>
                            </div>                           
                        </div>
                        <div class="banner">
                            <div class="bannerbox">
                                <ul>
                                    <li>
                                        <div class="imgbox">
                                            <img src="../assets/img/ia_300004232.jpg" alt="" class="auto-img">
                                        </div>
                                        <div class="box">
                                            <a href="" style="margin-left: 0;">天生会飞</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="imgbox">
                                            <img src="../assets/img/ia_300004234.jpg" alt="" class="auto-img">
                                        </div>
                                        <div class="box">
                                            <a href="" style="margin-left: 0;">天生会飞</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="imgbox">
                                            <img src="../assets/img/ia_300004236.jpg" alt="" class="auto-img">
                                        </div>
                                        <div class="box">
                                            <a href="" style="margin-left: 0;">天生会飞</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="imgbox">
                                            <img src="../assets/img/ia_300004238.jpg" alt="" class="auto-img">
                                        </div>
                                        <div class="box">
                                            <a href="" style="margin-left: 0;">天生会飞</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="imgbox">
                                            <img src="../assets/img/ia_300004234.jpg" alt="" class="auto-img">
                                        </div>
                                        <div class="box">
                                            <a href="" style="margin-left: 0;">天生会飞</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="imgbox">
                                            <img src="../assets/img/ia_300004238.jpg" alt="" class="auto-img">
                                        </div>
                                        <div class="box">
                                            <a href="" style="margin-left: 0;">天生会飞</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img :src="url" alt=""> -->
        <!-- <video :src="url" controls></video> -->
        <!-- 底部 -->
        <foot-navbar></foot-navbar>
       
        
        
    </div>
</template>

<script>
import Swiper from "swiper";
// import HeadNavbar from '../components/HeadNavbar.vue'
import FootNavbar from '@/components/FootNavbar.vue';
import { getBanner } from '../api/banner'
import {  getLists,getListstwo  } from '../api/lists'
  export default {
    components: {  FootNavbar },
    data() {
        return {
            Banner: [],
            Lists:[],
            Liststwo:[],
            Listsnew:[],
            Listsold:[],
            i:0,
            j:0,
            k:0,
            url:"",
            obj:{},
            newLists:[]
        };
    },
    methods:{
        // 点赞功能
        goods(i){
            // 判断是否登录
            let token = window.localStorage.getItem("token");
            if(!token){
                this.$router.push("/record")
                return
            }
            //获取当前id所在数组下标
            let index=this.Lists.findIndex((item)=>item.slug==i);
            // 如果当前id下的数组中的goods==0为取消点赞
            if(this.Lists[index].goods==0){
                // 改变点赞状态为1 已点赞为goods==1
                this.Lists[index].goods=1; 
                // 点赞数加1
                this.Lists[index].like_count+=1;
                //将数据存入local story中
                window.localStorage.setItem("Listsnew",JSON.stringify([this.Lists]));
            }else if(this.Lists[index].goods==1){
                this.Lists[index].goods=0;
                this.Lists[index].like_count-=1;
                window.localStorage.setItem("Listsnew",JSON.stringify([this.Lists]));
            }
        },
        // 跳转方法浏览量加1
        router_details(id){
            // 跳转到对应id的详情页 
            this.$router.push("/details?id="+ id +"");
            let index = this.Lists.findIndex((item)=>item.slug==id);
            // 判断是否登录
            let token = window.localStorage.getItem("token");
            //如果该数据中不存在该数据 则在该数组中添加checked数据，如果不存在浏览量加1
            //并将checked改为true实现只加一次
            if(!("checked" in this.Lists[index]) && token){
                this.Lists[index].view_count += 1;
                this.Lists[index].checked = true;
            }
            window.localStorage.setItem("Listsnew",JSON.stringify([this.Lists]));
          
        },
        // 列表轮播指示点
        cut(index){
          this.i=index;
          //通过ref名为banner获取原生dom操作css样式
          this.$refs.banner.style.transform="translateX(-"+ 1220 *index +"px)";
        },
        cut1(index){
          this.j=index;
          //通过ref名为banner获取原生dom操作css样式
          this.$refs.banner1.style.transform="translateX(-"+ 1220 *index +"px)";
        },
        cut2(index){
          this.k=index;
          //通过ref名为banner获取原生dom操作css样式
          this.$refs.banner2.style.transform="translateX(-"+ 1220 *index +"px)";
        },
        user(id){
            console.log(id);
            this.$router.push("/user?id="+ id +"");

        },
        getBannerFun(){
            getBanner().then(data=>{
                this.Banner=data.banner;
            })
        },
        getListsFun(){
            getLists().then(data=>{
                //列表数据
                this.Lists=data.data.items;
                // this.Listsold=data.data.items;
                // 给每个数据添加新数据  
                for(let i=0;i<this.Lists.length;i++){
                    // 点赞状态goods
                    this.Lists[i].goods=0;
                    // 收藏collect
                    this.Lists[i].collect=false;
                    // 关注状态
                    this.Lists[i].care=false;

                }
                // local story如果存在数据则使用localstory中的数据
                if(window.localStorage.getItem("Listsnew")){
                    this.Lists=JSON.parse(window.localStorage.getItem("Listsnew"))[0];
                }
                // 将相同id的数据存入同一个数组
                this.Lists.forEach(item=>{
                    if(this.obj[item.user.slug]){
                        this.obj[item.user.slug].push(item)
                    }else{
                        this.obj[item.user.slug]=[item];
                        this.newLists.push(this.obj[item.user.slug])
                    }
                })
                console.log(this.newLists);
                window.localStorage.setItem("newLists",JSON.stringify(this.newLists));
                if(window.localStorage.getItem("newLists")){
                    this.newLists=JSON.parse(window.localStorage.getItem("newLists"));
                }    
            })
        },
        SwiperFun(){
            new Swiper(".mySwiper", {
                observer:true,//自动初始化swiper对象
                observeParents:true,//自动初始化父节点swiper对象
                spaceBetween: 30,//轮播图间隔
                centeredSlides: true,//居中
                loop: true,//无限循环
                autoplay: {//自动播放
                delay: 2500,
                disableOnInteraction: false,//true: 触碰后停止自动切换
                },
                pagination: {
                el: ".swiper-pagination",//指示点类名
                clickable: true,//点击指示点切换
                //  bulletClass : 'my-bullet',//需设置.my-bullet样式
                //  bulletActiveClass: 'my-bullet-active',选中类名可自定义样式
                },
            });
            
        }
    },
    //过滤器
    filters:{
        // 时间过滤
        Time(data){
            let t=data.split('T')[0].split("-");
            // console.log(t);
            let time=t[0]+"年"+t[1]+"月"+t[2]+"日";
            // console.log(time);
            return time;
        }
    },
    created(){
        this.getBannerFun();
        this.getListsFun();
        this.$emit("login_local");
        getListstwo().then((data)=>{
            this.Liststwo=data.data.items;
            // console.log(this.Liststwo);
        })
    },
    mounted(){
        this.SwiperFun();
        },
    updated(){
        // this.SwiperFun();
        
    }
  }
</script>

<style lang="scss" scoped>
.swiper-pagination ::v-deep .swiper-pagination-bullet{
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background: #ddd;
    opacity: .8;
    margin: 0 5px;
}
.swiper-pagination ::v-deep .swiper-pagination-bullet-active{
    background: #1088f2;
}
.swiper ::v-deep {
    position: relative;
}
.home {
    .line{
        height: 0;
        width: 100%;
        border-top: 1px solid #eee;
    }
  .hot {
    padding-bottom: 72px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      p {
        font-size: 27px;
        font-weight: bold;
      }
      a {
        color: #1088f2;
        font-size: 15px;
        align-self: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .main {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 16px;
        margin-top: 8px;
      }
    }
    .imgbox {
      width: 180px;
      height: 120px;
      border-radius: 5px;
      overflow: hidden;
      img {
        transition: all 0.15s linear;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  
  .titlebox {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 59px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 1px #eee;
    z-index: 999;
    li {
      height: 60px;
      font-weight: bold;
      font-size: 14px;
      border-bottom: 2px solid #1088f2;
      display: flex;
      align-items: center;
    }
  }
  .content {
    background-color: #f7f8f9;
    .mainbox {
      padding: 40px 0;
    }
  }
  .natural {
    .bannerbox {
      ul {
        // display: flex;
        // flex-wrap: wrap;
        transition: all 0.3s linear;
        transform: translateY(0px);
        width: 5000px;
        overflow: hidden;
        li {
            float: left;
          & > .imgbox {
            width: 285px;
            height: 190px;
          }
          & > .back{

                background-size: cover;
                background-position: 50%;
            }
           .time{
            color: #999;
          }
          & > .content {
            padding: 10px 13px;
            font-size: 12px;
          }
        }
      }
      .content {
        background-color: #fff;
        & > p {
          font-size: 14px;
          font-weight: bold;
          overflow: hidden;

          text-overflow:ellipsis;

          white-space: nowrap;
          &:hover {
            color: #1088f2;
          }
        }
        .device {
          color: #999;
          margin: 10px 0;
        }
      }
    }
    .device {
      span {
        color: #1088f2;
        &:hover{
            text-decoration: underline;
        }
      }
    }
    .state {
      display: flex;
      margin-bottom: 10px;
      .icon {
        width: 16px;
        height: 16px;
      }
      p {
        color: #999;
        font-size: 13px;
        margin: 0 5px;
      }
    }
    .user {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .userimg {
        display: flex;
        align-items: center;
      }
      p {
        font-size: 13px;
        margin-left: 5px;
        &:hover {
            color: #1088f2;
        }
      }
      .imgbox {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .mainfoot {
    .bannerbox {
        
      ul {
        display: flex;
        
        li {
          & > .imgbox {
            width: 181px;
            height: 122px;
          }
        }
      }
      .box {
        width: 183px;
        height: 49px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #000;
          &:hover {
            color: #1088f2;
          }
        }
      }
    }
  }
}
.home .natural .title,
.home .mainfoot .title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.home .natural .title .right,
.home .mainfoot .title .right {
  display: flex;
  align-items: center;
}
.home .natural p,
.home .mainfoot p {
  font-size: 20px;
}
.home .natural a,
.home .mainfoot a {
  font-size: 14px;
  color: #1088f2;
  margin-left: 40px;
}
.home .natural .bannerbox ul li:first-child,
.home .mainfoot .bannerbox ul li:first-child {
  margin-right: 10px;
  margin-left: 0;
}
.home .natural .bannerbox ul li:last-child,
.home .mainfoot .bannerbox ul li:last-child {
  margin-left: 10px;
  margin-right: 0;
}

.home {
  .banner {
    padding: 40px 0px;
    width: 1200px;
    overflow: hidden;
       .li {
        width: 1200px;
        height: 320px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
      }
    
  }
 
  .natural {
    margin-bottom: 40px;
    .banner {
      padding: 0;
    }
    .bannerbox {
      ul {
        
        li {
          margin: 0 10px;
          border-radius: 2px;
          overflow: hidden;
          width: 285px;
          height: 320px;
          background-color: #fff;
          cursor: pointer;
          .back{

            background-size: cover;
            background-position: 50%;
            
          }
        }
      }
    }
    .right{
        ol{
            cursor: pointer;
            display: flex;
            list-style: none;
            li{
                width: 12px;
                height: 12px;
                border-radius: 3px;
                background: #ddd;
                opacity: .8;
                margin: 0 5px;
                &.con{
                    background-color: #1088f2;
                }
            }
        }
    }
  }
  .mainfoot {
    ol{
            cursor: pointer;
            display: flex;
            list-style: none;
            li{
                width: 12px;
                height: 12px;
                border-radius: 3px;
                background: #ddd;
                opacity: .8;
                margin: 0 5px;
                &.con{
                    background-color: #1088f2;
                }
            }
        }
    .bannerbox {
      ul {
        li {
          margin: 0 10px;
          width: 181px;
          height: 171px;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0 0 4px #ccc;
        }
        margin-bottom: 5px;
      }
    }
    .banner {
      padding: 0;
    }
  }
}

</style>