import request from './http';

export function getLists(params){
    return request({
        //请求接口
        // url:"/api/v2/topics/nature/works?lang=zh-Hans&platform=web&device=desktop&filter=featured:true&sort=hot&limit=16&offset=0",
        url:"./json/lists.json",
        method:"get" ,
        params   
    })
}


export function getListstwo(){
    return request({
        //请求接口
        url:"/api/v2/topics/nature/works?lang=zh-Hans&platform=web&device=desktop&filter=featured:true&sort=hot&limit=16&offset=0",
        // url:"./json/lists.json",
        method:"get"
    })
}